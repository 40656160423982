exports.components = {
  "component---src-components-product-page-product-page-tsx": () => import("./../../../src/components/productPage/productPage.tsx" /* webpackChunkName: "component---src-components-product-page-product-page-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout/success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-men-tsx": () => import("./../../../src/pages/men.tsx" /* webpackChunkName: "component---src-pages-men-tsx" */),
  "component---src-pages-women-tsx": () => import("./../../../src/pages/women.tsx" /* webpackChunkName: "component---src-pages-women-tsx" */)
}

